import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { size, palette, font } from 'styled-theme';
import { ConfigProvider, Empty, Tag } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import get from 'lodash/get';
import P from '../../../components/atoms/P';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import Flex from '../../../components/atoms/Flex';
import { selectUser } from '../../../store/authentication/selectors';
import { signInSuccess as signInRequest } from '../../../store/authentication/actions';
import Button from '../../../components/atoms/Button';
import PopUpDelete from '../../../components/organisms/PopUpDelete';

const Wrapper = styled.div`
  padding-bottom: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
    height: 100%;
  }
`;

const StyledTag = styled(Tag)`
  min-width: 70px;
  text-align: center;
  font-size: 14px;
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 25px 5px 25px 0px;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const ModelContainer = styled.div`
  @media (max-width: ${size('mobileBreakpoint')}) {
    height: calc(100% - 56px);
  }
  > div {
    @media (max-width: ${size('mobileBreakpoint')}) {
      height: 100%;
    }
    > div: first-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        max-height: ${(props) => (props.divHeight ? 'none' : '56px')};
      }
    }
    > div: last-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        height: calc(100% - 56px);
        height: ${(props) => (props.divHeight ? `calc(100% - ${props.divHeight}px)` : 'calc(100% - 56px)')}
      }
      > div {
        @media (max-width: ${size('mobileBreakpoint')}) {
          height: 100%;
        }
        > div {
          @media (max-width: ${size('mobileBreakpoint')}) {
            height: 100%;
          }
          > div: last-child {
            @media (max-width: ${size('mobileBreakpoint')}) {
              height: 100%;
            }
            > div {
              @media (max-width: ${size('mobileBreakpoint')}) {
                height: calc(100% - 40px);
              }
              > div {
                @media (max-width: ${size('mobileBreakpoint')}) {
                  height: 100%;
                }
                > div: last-child {
                  max-height: 100% !important;
                  @media (max-width: ${size('mobileBreakpoint')}) {
                    max-height: calc(100% - 55px) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Cell = styled.div`
  width: max-content;
`;

const modelName = 'library';

const itemsPerPage = 25;

const ListView = (props) => {
  const { user, location } = props;
  const [divHeight, setDivHeight] = useState(0);

  const cellRenderers = [
    {
      title: 'Product ID',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <Cell><Link to={{ pathname: `/library/show/${id}`, state: { prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` } }}>{`LIB-PR${id}`}</Link></Cell>,
      width: 120,
    },
    {
      title: 'Section',
      dataIndex: 'section',
      key: 'section',
      render: (content) => <Cell>{content}</Cell>,
      width: 100,
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      render: (content) => <Cell style={{ maxWidth: 300 }}>{content}</Cell>,
      width: 100,
    },
    {
      title: 'Publisher / Maker',
      dataIndex: 'publisher',
      key: 'publisher',
      render: (publisher) => <Cell style={{ maxWidth: 300 }}>{publisher || 'N/A'}</Cell>,
      width: 160,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
      render: (text, record) => <Cell>{(record.libraryDetails[0] && record.libraryDetails[0].id ? Number(record.libraryDetails.length) : 0)}</Cell>
    },
    {
      title: 'Status',
      key: 'checkoutId',
      render: (id, record) => {
        const isAvailable = record.libraryDetails.filter((detail) => {
          return detail.arrivalStatus !== 'Not Yet Arrived' && detail.checkoutId == null;
        }).length > 0;
        return (
          <Cell><StyledTag color={!isAvailable ? 'orange' : 'green'}>{!isAvailable ? 'Not Available' : 'Available'}</StyledTag></Cell>
        );
      },
      width: 90,
    },
    {
      title: 'Action',
      key: 'action',
      width: 90,
      render: (text, record) => (
        <Cell>
          <PopUpDelete user={user} endpoint="library" id={record.id} type="Product" />
        </Cell>
      ),
    },
  ];

  const filterConfig = [
    {
      type: 'multi-select',
      name: 'section',
      label: 'Section',
      endpoint: 'library/librarySections',
      valueBy: (v) => v.value,
      labelBy: (v) => v.label,
    },
    {
      type: 'multi-select',
      name: 'publisher',
      label: 'Publisher / Maker',
      endpoint: 'library/publisherOrMakerOptions',
      valueBy: (v) => v.publisher,
      labelBy: (v) => v.publisher,
    },
    {
      type: 'multi-select',
      name: 'instructorId',
      label: 'Instructor Checkout',
      labelBy: (v) => `${v.firstName} ${v.lastName}`,
      valueBy: (v) => String(v.id),
      endpoint: 'user',
      query: {
        where: { role: 'instructor' },
      },
    },
  ];

  return (
    <Wrapper>
      <Flex style={{ position: 'relative' }}>
        <HeaderText> Library </HeaderText>
      </Flex>
      <ModelContainer divHeight={divHeight} role={user.role}>
        <ModelPage
          showHeader={false}
          modelName={modelName}
          itemsPerPage={itemsPerPage}
          allowSearch
          showCreateButton={user.role === 'admin' || user.role === 'superAdmin' || user.role === 'libraryHelper'}
          query={{ where: { type: 'All' } }}
          showExportButton={user.role === 'admin' || user.role === 'superAdmin' || user.role === 'libraryHelper'}
          createState={{ prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` }}
          initialQuery={{
            currentPage: 1,
            where: {
              type: 'All',
            },
          }}
          filterConfig={filterConfig}
          radioGroup={['All', 'Accessory', 'Book', 'Instrument', 'Others']}
          radioGroupKey="type"
          {...props}
          render={(renderProps) => {
            const { height } = renderProps;
            if (divHeight !== height) setDivHeight(height);
            return (
              <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Products" />}>
                <AntDTable
                  modelName={modelName}
                  cellRenderers={user.role === 'instructor' ? cellRenderers.filter((c) => c.title !== 'Action') : cellRenderers}
                  scroll={{ x: 'max-content', y: '65vh' }}
                  pagination={{ pageSize: itemsPerPage, showQuickJumper: true, hideOnSinglePage: true, showSizeChanger: false }}
                  rowKey="id"
                  {...renderProps}
                />
              </ConfigProvider>
            )
          }}
        />
      </ModelContainer>
    </Wrapper>
  )
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  user: selectUser(state.authentication),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitSuccess: (values) => dispatch(signInRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
