/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import { size, palette, font } from 'styled-theme';
import { ConfigProvider, Empty, Tag } from 'antd';
import { ifProp, prop } from 'styled-tools';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment'
import Popup from 'reactjs-popup';
import { Form } from 'react-final-form';
import ModelPage from '../../../containers/ModelPage';
import AntDTable from '../../../components/organisms/AntDTable';
import Link from '../../../components/atoms/Link';
import { selectUser } from '../../../store/authentication/selectors';
import { signInSuccess as signInRequest } from '../../../store/authentication/actions';
import useGeneralData from '../../../hooks/useGeneralData';
import PopUpRequest from '../../../components/organisms/PopUpRequest';
import PopUpLesson from '../../../components/organisms/PopupLesson';
import Flex from '../../../components/atoms/Flex';
import ErrorAlert from '../../../components/molecules/ErrorAlert';
import useCalendarForm from '../../../hooks/useCalendarForm';
import Button from '../../../components/atoms/Button';
import Loading from '../../../components/atoms/Loading';
import PopUpDeleteLesson from '../../../components/organisms/PopUpDeleteLesson';
import Card from '../../../components/atoms/Card';
import inputOptions from '../../../utils/form/inputOptions';

const Wrapper = styled.div`
  padding-bottom: 10px;
  @media (max-width: ${size('mobileBreakpoint')}) {
    padding-bottom: 0px;
    height: 100%;
  }
`;

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: bold;
  padding: 25px 5px 25px 0px;
  font-family: ${font('tertiary')};

  @media (max-width: ${size('mobileBreakpoint')}) {
    padding: 20px 12px;
    max-height: 56px;
  }
`;

const StyledSpan = styled.span`
  display: inline-block;
`;

const StyledTag = styled(Tag)`
  min-width: 70px;
  text-align: center;
  font-size: 14px;
  width: 100%;
`;

const StyledButton = styled(Button)`
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  color: #3E90F7;
  height: 20px;
  line-height: 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
    color: #3E90F7;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledPopup = styled(Popup)`
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const PopUpWrapper = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 0px;
  width: 30vw;
  min-width: 30vw;
  padding: 20px;

  @media (max-width: ${size('mobileBreakpoint')}) {
    margin-left: 0px;
    margin-right: 0px;
    width: 90vw;
  }
`;

const PopUpTextWrapper = styled.div`
  font-family: ${font('tertiary')};
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px 10px 0px;
  border-bottom: 1px solid ${palette('grayscale', 0)};
  margin-bottom: 10px;
`;

const PopUpButton = styled(Button)`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  font-size: 25px;
  position: absolute;
  right: 40px;
  top: 20px;

  &:hover,
  &:focus,
  &:active {
    background-color: white;
    color: ${palette('black', 0)};
  }

  &:focus {
    outline: none;
  }

  @media (max-width: ${size('mobileBreakpoint')}) {
    right: 15px;
  }
`;

const PopUpErrorAlertContainer = styled.div`
  > div {
    max-width: 28vw;
    @media (max-width: ${size('mobileBreakpoint')}){
      max-width: none;
    }
  }
`;

const StyledForm = styled.form`
  div[role='alert'] {
    min-height: 12px;
  }
`;

const StyledLink = styled(Link)`
  color: #3E90F7;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;

  &:hover,
  &:focus,
  &:active {
    color: #3E90F7;
  }
`;

const StudentLink = styled(Link)`
  color: ${ifProp('red', '#FF0000', '#3E90F7')};

  &:hover,
  &:focus,
  &:active {
    color: ${ifProp('red', '#FF0000', '#3E90F7')};
  }
`;

const OverrideButton = styled(Button)`
  min-width: 100px;
  margin: 0px;
  margin-top: 10px;
  border-radius: 0px;
`;

const ModelContainer = styled.div`
  @media (max-width: ${size('mobileBreakpoint')}) {
    height: calc(100% - 56px);
  }
  > div {
    @media (min-width: calc(${size('mobileBreakpoint')} + 1px)){
      padding: 24px 24px 8px 24px;

      .ant-table-pagination-right {
        flex-wrap: wrap;
      }

      .ant-pagination-options {
        margin-bottom: 16px;
      }

      .ant-pagination-prev {
        margin-bottom: 16px;
      }
    }

    @media (max-width: ${size('mobileBreakpoint')}) {
      height: 100%;
    }
    > div: first-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        max-height: ${(props) => (props.divHeight ? 'none' : '56px')}
      }
    }
    > div: last-child {
      @media (max-width: ${size('mobileBreakpoint')}) {
        height: calc(100% - 56px);
        height: ${(props) => (props.divHeight ? `calc(100% - ${props.divHeight}px)` : 'calc(100% - 56px)')}
      }
      > div {
        @media (max-width: ${size('mobileBreakpoint')}) {
          height: 100%;
        }
        > div {
          @media (max-width: ${size('mobileBreakpoint')}) {
            height: 100%;
          }
          > div: last-child {
            @media (max-width: ${size('mobileBreakpoint')}) {
              height: 100%;
            }
            > div {
              @media (max-width: ${size('mobileBreakpoint')}) {
                height: calc(100% - 40px);
              }
              > div {
                @media (max-width: ${size('mobileBreakpoint')}) {
                  height: 100%;
                }
                > div: last-child {
                  max-height: 100% !important;
                  @media (max-width: ${size('mobileBreakpoint')}) {
                    max-height: calc(100% - 55px) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Note = styled.div`
  max-width: 250px;
`;

const Cell = styled.div`
  width: max-content;
`;

const StyledText = styled.div`
  font-family: ${font('primary')};
  color: ${palette('black', 0)};
  padding: 20px 0px 30px 0px;
  text-align: center;
`;

const SubmitButton = styled(Button)`
  display: flex;
  flex-direction: row;
  border-radius: 0px;
  margin-bottom: 0px;
  width: 100%;
`;

const modelName = 'lesson';

const ListView = (props) => {
  const { user, match, location } = props;
  const itemsPerPage = 25;
  const [revertErrorMessage, setRevertErrorMessage] = useState(null);
  const [revertAllErrorMessage, setRevertAllErrorMessage] = useState(null);
  const [revertId, setRevertId] = useState(null);
  const [errorFormValue, setErrorFormValue] = useState(null);
  const [divHeight, setDivHeight] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDoubleSubmit, setIsDoubleSubmit] = useState(false);
  const [selectedLessons, setSelectedLessons] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [cancelAll, setCancelAll] = useState(null);
  const [deleteAll, setDeleteAll] = useState(null);
  const [revertAll, setRevertAll] = useState(null);

  const [popupErrorMessage, setPopupErrorMessage] = useState(null);

  const { data, loading } = useGeneralData({
    endpoint: 'lesson/lessonData',
    query: { recurrenceId: null },
  });

  const { onSubmit } = useCalendarForm({
    endpoint: 'event/revert',
    setErrorMessage: setRevertErrorMessage,
    user,
    type: 'lessonRevert',
    setErrorFormValue,
  });

  const { onSubmit: onRevertAll } = useCalendarForm({
    endpoint: 'event/revertAll',
    setErrorMessage: setRevertAllErrorMessage,
    user,
    type: 'lessonRevertAll',
    setErrorFormValue,
  });

  const { onSubmit: onCancelAll } = useCalendarForm({
    endpoint: 'event/cancelSelected',
    setErrorMessage: setPopupErrorMessage,
    user,
    type: 'deleteAll',
  });

  const { onSubmit: onDeleteAll } = useCalendarForm({
    endpoint: 'event/deleteSelected',
    setErrorMessage: setPopupErrorMessage,
    user,
    type: 'deleteAll',
  });

  useEffect(() => {
    if (revertErrorMessage !== null && !errorFormValue) {
      setTimeout(() => {
        setRevertErrorMessage(null);
        setIsSubmitting(false);
      }, 3000);
    }
  }, [errorFormValue, revertErrorMessage]);

  useEffect(() => {
    if (popupErrorMessage !== null) {
      setTimeout(() => {
        setPopupErrorMessage(null);
      }, 3000);
    }
  }, [popupErrorMessage]);

  if (user.role === 'parent') return <Redirect to="/calendar" />;

  const lessonCellRenderers = [
    {
      title: 'Lesson',
      width: 90,
      render: (key, row) => <Cell><Link to={{ pathname: `/lesson/show/${row.recurrenceId}`, state: { prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` } }}>{row.title}</Link></Cell>,
    },
    {
      title: 'Type',
      dataIndex: 'lessonType',
      key: 'lessonType',
      width: 75,
      render: (lessonType) => <Cell>{(lessonType ? lessonType.split(' ')[0] : 'N/A')}</Cell>,
    },
    {
      title: 'Recurring',
      dataIndex: 'formattedRecurring',
      key: 'formattedRecurring',
      width: 100,
      render: (key, row) => <Cell style={{ maxWidth: 220 }}>{(row.recurrenceType === 'Does not repeat' ? row.recurrenceType : `${row.recurrenceType} (${moment(row.date).format('dddd')})`)}</Cell>,
    },
    {
      title: 'Time',
      dataIndex: 'formattedTime',
      key: 'formattedTime',
      width: 75,
      render: (key, row) => <Cell>{`${moment.tz(row.startTime, 'Asia/Singapore').format('HH:mm a')} - ${moment.tz(row.endTime, 'Asia/Singapore').format('HH:mm a')}`}</Cell>,
    },
    {
      title: 'Student(s)',
      dataIndex: 'child',
      key: 'child',
      width: 100,
      render: (students) => (
        <Cell style={{ maxWidth: 300 }}>
          {user.role === 'parent'
            ? (students.filter((c) => Number(c.userId) === Number(user.id)).length > 0 ? (students.filter((c) => Number(c.userId) === Number(user.id)).map((s, index) => <span>{(index ? ', ' : '')}<StyledSpan style={{ textDecoration: s.isCancelled && 'line-through' }}>{(user.role === 'parent' && (Number(get(s, 'child.parentId') !== Number(user.id)))) ? `${get(s, 'child.firstName', null)} ${get(s, 'child.lastName', null)}` : <StudentLink to={`/student/show/${get(s, 'child.id', null)}`}>{`${get(s, 'child.firstName', null)} ${get(s, 'child.lastName', null)}`}</StudentLink>}</StyledSpan></span>))
              : 'N/A')
            : students.map((s, index) => {
              const packagePrefix = get(s, 'package.type', null) === 'Group Package' ? 'G' : get(s, 'package.type', null) === 'Trial Package' ? 'T' : 'P';
              return (
                <span>
                  {(index ? ', ' : '')}
                  <StyledSpan style={{ textDecoration: s.isCancelled && 'line-through' }}>
                    <StudentLink to={`/student/show/${get(s, 'child.id', null)}`}>
                      {`${get(s, 'child.firstName', null)} ${get(s, 'child.lastName', null)}`}
                    </StudentLink>
                    { (user.role === 'admin' || user.role === 'superAdmin') && (
                      <span>
                        {' ('}
                        <StudentLink to={`/package/show/${get(s, 'package.id', null)}`}>
                          {packagePrefix}{get(s, 'package.id', null)}
                        </StudentLink>
                        {')'}
                      </span>
                    )}
                  </StyledSpan>
                </span>
              )
            })}
        </Cell>
      ),
    },
    {
      title: 'Instructor',
      dataIndex: 'user',
      key: 'user',
      width: 120,
      render: (instructor) => <Cell>{(user.role === 'superAdmin' ? <Link to={`/staff/show/${instructor.id}`}>{`${instructor.firstName} ${instructor.lastName}`}</Link> : `${instructor.firstName} ${instructor.lastName}`)}</Cell>,
    },
    {
      title: 'Room',
      dataIndex: 'room',
      key: 'room',
      width: 75,
      render: (room) => <Cell>{get(room, 'name', null) || 'N/A'}</Cell>,
    },
  ];

  const classCellRenderers = [
    {
      title: 'Class',
      dataIndex: 'title',
      key: 'title',
      width: 90,
      render: (title) => <Cell>{title}</Cell>,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (date) => <Cell>{`${moment(date).format('DD/MM/YYYY')}`}</Cell>,
      width: 75,
    },
    {
      title: 'Time',
      dataIndex: 'formattedTime',
      key: 'formattedTime',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Student(s)',
      dataIndex: 'studentNamesWithStatus',
      key: 'studentNamesWithStatus',
      width: 100,
      render: (studentNamesWithStatus) => (
        <Cell style={{ maxWidth: 300 }}>
          {(
            user.role === 'parent'
              ? studentNamesWithStatus.filter((c) => Number(c.userId) === Number(user.id)).map((s, index) => <span>{(index ? ', ' : '')}<StyledSpan style={{ textDecoration: s.showCancelled && 'line-through' }}>{s.parentId === Number(user.id) ? <StudentLink to={`/student/show/${s.id}`} red={s.hasNoCredit && s.parentId === Number(user.id)}>{s.nameWithStatus}</StudentLink> : s.nameWithStatus}</StyledSpan></span>)
              : studentNamesWithStatus.map((s, index) => (
                <span>{(index ? ', ' : '')}
                  <StyledSpan style={{ textDecoration: s.showCancelled && 'line-through' }}>
                    <StudentLink to={`/student/show/${s.id}`} red={(user.role === 'admin' || user.role === 'superAdmin' || user.id === 409) && s.hasNoCredit}>
                      {s.nameWithStatus}
                    </StudentLink>
                    { (user.role === 'admin' || user.role === 'superAdmin' || user.id === 409) && (
                      <span>
                        {' ('}
                        <StudentLink to={`/package/show/${get(s, 'formattedPackage.id', null)}`} red={(user.role === 'admin' || user.role === 'superAdmin' || user.id === 409) && s.hasNoCredit}>
                          {get(s, 'formattedPackage.prefix', null)}{get(s, 'formattedPackage.id', null)}
                        </StudentLink>
                        {')'}
                      </span>
                    )}
                  </StyledSpan>
                </span>
              ))
          )}
        </Cell>
      ),
    },
    {
      title: 'Instructor',
      dataIndex: 'instructor',
      key: 'instructor',
      width: 120,
      render: (instructor) => <Cell>{(user.role === 'superAdmin' ? <Link to={`/staff/show/${instructor.id}`}>{instructor.name}</Link> : instructor.name)}</Cell>,
    },
    {
      title: 'Room',
      dataIndex: 'roomName',
      key: 'roomName',
      render: (content) => <Cell>{content}</Cell>,
      width: 75,
    },
    {
      title: 'Class Status',
      dataIndex: 'isCancelled',
      key: 'isCancelled',
      width: 130,
      render: (cancelled, record) => {
        let text = cancelled ? 'Cancelled' : 'Confirmed';
        text = record.deletedAt ? "Deleted" : text;
        return <Cell><StyledTag color={cancelled ? 'red' : 'green'} key={cancelled}>{text}</StyledTag></Cell>
      },
    },
    {
      title: 'Cancelled Date',
      dataIndex: 'cancelledAt',
      key: 'cancelledAt',
      width: 80,
      render: (content) => <Cell>{content ? moment(content).format('YYYY-MM-DD HH:mm') : ''}</Cell>,
    },
    ...(user.role !== 'parent' ? (
      [{
        title: 'Note (internal)',
        dataIndex: 'note',
        key: 'note',
        render: (note) => <Cell><Note> { note || 'N/A' } </Note></Cell>,
        width: 75,
      }]
    ) : []),
    {
      title: 'Note',
      dataIndex: 'externalNote',
      key: 'externalNote',
      render: (note) => <Cell><Note> { note || 'N/A' } </Note></Cell>,
      width: 75,
    },
    {
      title: 'Action',
      key: 'action',
      width: 90,
      render: (text, record) => {
        return (
          <Cell style={{ width: user.role === 'parent' ? 120 : 140, maxWidth: user.role === 'parent' ? 120 : 140 }}>
            {(user.role === 'admin' || user.role === 'superAdmin') && (
              <div>
                { !record.isCancelled ? (
                  <Flex>
                    <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}> <StyledLink to={{ pathname: `/lesson/${record.recurrenceId}/editSingle/${record.id}`, state: { prevPath: `${get(location, 'pathname', '')}${get(location, 'search', '')}` } }} disabled={record.hasAttendanceConfirmed} style={{ color: record.hasAttendanceConfirmed && '#C1C1C1' }}> Edit </StyledLink></div>
                    <div style={{ paddingLeft: 5, borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
                      <PopUpLesson
                        user={user}
                        child={data.children}
                        instructor={data.instructors}
                        selectedEvent={record}
                        id={record.eventId}
                        studentId={record.studentIds}
                        eventInstructor={[record.instructorId]}
                        allLessons={data.allLessons}
                        type="delete"
                        page="lesson"
                      />
                    </div>
                    <div style={{ paddingLeft: 5 }}>
                      <PopUpDeleteLesson user={user} selectedEvent={record} id={record.eventId} type="delete" page="lesson" />
                    </div>
                  </Flex>
                ) : (
                  <Flex>
                    <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
                      <StyledButton disabled={isSubmitting && !revertErrorMessage} onClick={() => { setIsSubmitting(true); onSubmit(record.eventId); setRevertId(record.eventId) }}> Revert </StyledButton>
                    </div>
                    <div style={{ paddingLeft: 5 }}>
                      <PopUpDeleteLesson user={user} selectedEvent={record} id={record.eventId} type="delete" page="lesson" />
                    </div>
                  </Flex>
                )}
              </div>
            )}
            {user.role === 'parent' && (
              <div>
                { record.formattedType === 'Private' ? (
                  <Flex>
                    <div style={{ borderRight: '1px solid #ADADAD', paddingRight: 5 }}>
                      <PopUpRequest
                        user={user}
                        id={record.id}
                        child={[{ firstName: get(record, 'child.firstName', null), lastName: get(record, 'child.lastName', null), id: get(record, 'child.id', null) }]}
                        instructor={[{ firstName: get(record, 'thisInstructor.firstName', null), lastName: get(record, 'thisInstructor.lastName', null), id: get(record, 'thisInstructor.id', null) }]}
                        selectedEvent={record}
                        eventInstructor={[record.instructorId]}
                        requests={data.requests}
                        allLessons={[{ name: record.title }]}
                        isCancelled={record.isCancelled}
                        type="reschedule"
                        page="lesson"
                      />
                    </div>
                    <div style={{ paddingLeft: 5 }}>
                      <PopUpRequest
                        user={user}
                        id={record.id}
                        child={[{ firstName: get(record, 'child.firstName', null), lastName: get(record, 'child.lastName', null), id: get(record, 'child.id', null) }]}
                        instructor={[{ firstName: get(record, 'thisInstructor.firstName', null), lastName: get(record, 'thisInstructor.lastName', null), id: get(record, 'thisInstructor.id', null) }]}
                        selectedEvent={record}
                        eventInstructor={[record.instructorId]}
                        requests={data.requests}
                        allLessons={[{ name: record.title }]}
                        isCancelled={record.isCancelled}
                        type="cancel"
                        page="lesson"
                      />
                    </div>
                  </Flex>
                ) : 'N/A' }
              </div>
            )}
          </Cell>
        )
      },
    },
  ];

  const filterConfig = [
    {
      type: 'radioGroup',
      name: 'lessonType',
      label: 'Type',
      options: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: 'Private',
          value: 'Private Lesson',
        },
        {
          label: 'Semiprivate',
          value: 'Semiprivate Lesson',
        },
        {
          label: 'Group',
          value: 'Group Lesson',
        },
      ],
    },
    {
      type: 'radioGroup',
      name: 'classStatus',
      label: 'Class Status',
      options: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: 'Confirmed',
          value: 'Confirmed',
        },
        {
          label: 'Cancelled',
          value: 'Cancelled',
        },
      ],
    },
    {
      type: 'radioGroup',
      name: 'packageType',
      label: 'Package Type',
      options: [{ label: 'All', value: 'All' }, ...inputOptions.packageTypeOptions],
    },
    {
      type: 'radioGroup',
      name: 'freeTrial',
      label: 'Free Trial Class',
      options: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: 'Free Trial',
          value: 'Free Trial',
        },
      ],
    },
    {
      type: 'dateRange',
      name: 'date',
      label: 'Date',
      showDate: true,
      showTime: false,
    },
    {
      type: 'multi-select',
      name: 'title',
      label: 'Lesson',
      endpoint: 'lesson/lessonOptions',
      valueBy: (v) => v.lessonName,
      labelBy: (v) => v.lessonName,
    },
    {
      type: 'spec',
      name: 'childId',
      label: 'Student',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => `${v.firstName} ${v.lastName}`,
      reference: {
        endpoint: 'student',
        query: {
          where: user.role === 'parent' ? { parentId: user.id } : user.role === 'instructor' ? { 'lessons.instructorId': user.id } : {},
          order: {
            sortKey: [
              'child.firstName',
              'child.lastName',
            ],
            sortOrder: 'asc',
          },
        },
      },
      associationType: 'own',
    },
    {
      type: 'spec',
      name: 'instructorId',
      label: 'Instructor',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => `${v.firstName} ${v.lastName}`,
      reference: {
        endpoint: 'user',
        query: {
          where: {
            role: 'instructor',
            status: 'Current',
          },
          order: {
            sortKey: [
              'firstName',
              'lastName',
            ],
            sortOrder: 'asc',
          },
        },
      },
      associationType: 'own',
    },
    {
      type: 'spec',
      name: 'roomId',
      label: 'Room',
      isMulti: true,
      shouldChose: true,
      labelBy: (v) => v.name,
      reference: {
        endpoint: 'room',
      },
      associationType: 'own',
    },
  ];

  return (
    <Wrapper>
      {cancelAll ? (
        <StyledPopup
          open
          modal
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          {(close) => (
            <PopUpWrapper>
              <PopUpErrorAlertContainer>
                <ErrorAlert
                  errorMessage={popupErrorMessage}
                  show={popupErrorMessage !== null}
                />
              </PopUpErrorAlertContainer>
              <PopUpButton className="close" onClick={() => { close(); setCancelAll(false); }}>
                &times;
              </PopUpButton>
              <PopUpTextWrapper>
                Cancel All
              </PopUpTextWrapper>
              <StyledText>
                {`Are you sure you want to cancel the ${selectedClasses.length} selected ${selectedClasses.length > 1 ? 'classes' : 'class'}?`}
              </StyledText>
              <Form
                name="CancelledClassesFrom"
                onSubmit={onCancelAll}
                initialValues={null}
                initialValuesEqual={() => true}
                render={({ handleSubmit, values, submitting }) => {
                  values.ids = selectedClasses;
                  return (
                    <StyledForm onSubmit={handleSubmit}>
                      <SubmitButton type="submit" disabled={submitting}> Confirm </SubmitButton>
                    </StyledForm>
                  )
                }}
              />
            </PopUpWrapper>
          )}
        </StyledPopup>
      ) : null}
      {deleteAll ? (
        <StyledPopup
          open
          modal
          closeOnDocumentClick={false}
          closeOnEscape={false}
        >
          {(close) => (
            <PopUpWrapper>
              <PopUpErrorAlertContainer>
                <ErrorAlert
                  errorMessage={popupErrorMessage}
                  show={popupErrorMessage !== null}
                />
              </PopUpErrorAlertContainer>
              <PopUpButton className="close" onClick={() => { close(); setDeleteAll(false); }}>
                &times;
              </PopUpButton>
              <PopUpTextWrapper>
                Release All
              </PopUpTextWrapper>
              <StyledText>
                {`Are you sure you want to delete the ${selectedClasses.length} selected ${selectedClasses.length > 1 ? 'classes' : 'class'}?`}
              </StyledText>
              <Form
                name="DeleteClassesFrom"
                onSubmit={onDeleteAll}
                initialValues={null}
                initialValuesEqual={() => true}
                render={({ handleSubmit, values, submitting }) => {
                  values.ids = selectedClasses;
                  values.force = true;
                  return (
                    <StyledForm onSubmit={handleSubmit}>
                      <SubmitButton type="submit" disabled={submitting}> Confirm </SubmitButton>
                    </StyledForm>
                  )
                }}
              />
            </PopUpWrapper>
          )}
        </StyledPopup>
      ) : null}

      <Flex style={{ position: 'relative' }}>
        <HeaderText> Lesson Management </HeaderText>
        <Flex>
          <ErrorAlert
            errorMessage={
              revertErrorMessage ? (
                <div>
                  {revertErrorMessage.split(',').slice(0, 3).map((e) => <div style={{ marginBottom: 2 }}> {e} </div>)}
                  {revertErrorMessage.split(',').length > 3 ? <div style={{ marginBottom: 2 }}> and {revertErrorMessage.split(',').length - 3} more. </div> : null}
                  { errorFormValue === 403 ? (
                    <div>
                      <div style={{ marginTop: 8 }}> Would you still like to proceed? </div>
                      <Flex style={{ justifyContent: 'center' }}>
                        <OverrideButton disabled={isDoubleSubmit} onClick={() => { setIsDoubleSubmit(true); onSubmit(revertId, true) }} style={{ marginRight: 15 }}> Yes </OverrideButton>
                        <OverrideButton onClick={() => { setIsSubmitting(false); setRevertErrorMessage(null); setRevertId(null) }}> No </OverrideButton>
                      </Flex>
                    </div>
                  ) : null}
                </div>
              )
                : revertErrorMessage
            }
            show={revertErrorMessage !== null}
            style={{ maxWidth: 'none', marginTop: 8, left: 'calc(100vw / 2)' }}
          />
        </Flex>
      </Flex>
      <Flex style={{ position: 'relative' }}>
        <Flex>
          <ErrorAlert
            errorMessage={
              revertAllErrorMessage ? (
                <div>
                  {revertAllErrorMessage.split(',').slice(0, 3).map((e) => <div style={{ marginBottom: 2 }}> {e} </div>)}
                  {revertAllErrorMessage.split(',').length > 3 ? <div style={{ marginBottom: 2 }}> and {revertAllErrorMessage.split(',').length - 3} more. </div> : null}
                  { errorFormValue === 403 ? (
                    <div>
                      <div style={{ marginTop: 8 }}> Would you still like to proceed? </div>
                      <Flex style={{ justifyContent: 'center' }}>
                        <OverrideButton
                          disabled={isDoubleSubmit}
                          onClick={() => {
                            setIsDoubleSubmit(true);
                            onRevertAll({
                              ids: selectedClasses,
                              override: true,
                            })
                          }}
                          style={{ marginRight: 15 }}
                        >
                          Yes
                        </OverrideButton>
                        <OverrideButton
                          onClick={() => {
                            setIsSubmitting(false);
                            setRevertAllErrorMessage(null);
                          }}
                        >
                          No
                        </OverrideButton>
                      </Flex>
                    </div>
                  ) : null}
                </div>
              )
                : revertAllErrorMessage
            }
            show={revertAllErrorMessage !== null}
            style={{ maxWidth: 'none', marginTop: 8, left: 'calc(100vw / 2)' }}
          />
        </Flex>
      </Flex>
      <ModelContainer divHeight={divHeight} showMargin={user.role === 'admin' || user.role === 'superAdmin'}>
        <ModelPage
          {...props}
          showHeader={false}
          modelName={modelName}
          allowSearch
          itemsPerPage={itemsPerPage}
          showExportButton={user.role === 'admin' || user.role === 'superAdmin'}
          query={{ where: { page: 'Main', startTime: 'Upcoming' } }}
          initialQuery={{
            currentPage: 1,
            where: {
              startTime: 'Upcoming',
            },
          }}
          filterConfig={user.role === 'instructor' ? filterConfig.filter((f) => f.label !== 'Instructor' && f.label !== 'Package Type') : user.role === 'parent' ? filterConfig.filter((f) => f.label !== 'Package Type') : filterConfig}
          radioGroup={[
            'All',
            'Upcoming',
            'Completed',
          ]}
          radioGroupKey="startTime"
          showCreateButton={false}
          actions={(user.role === 'admin' || user.role === 'superAdmin') ? [
            { action: () => setCancelAll(true), label: 'Cancel All', disabled: !(selectedClasses && selectedClasses.length > 0) },
            { action: () => setDeleteAll(true), label: 'Release All', disabled: !(selectedClasses && selectedClasses.length > 0) },
            { action: () => onRevertAll({ ids: selectedClasses }), label: 'Revert All', disabled: !(selectedClasses && selectedClasses.length > 0) },
          ] : []}
          render={(renderProps) => {
            const { data, height, loading: tableLoading } = renderProps;
            const lessonsObj = groupBy(data, 'lesson.id');
            const lessonsArray = map(lessonsObj, (classes) => ({
              ...classes[0].lesson,
              classes,
            }));

            const sortedLessonsArray = sortBy(lessonsArray, ['classes[0].startTime', 'classes[0].endTime']);
            if (divHeight !== height) setDivHeight(height);
            if (selectedClasses && selectedClasses.length > 0 && tableLoading) {
              setSelectedClasses([]);
              setSelectedLessons([])
            }
            return (
              <ConfigProvider renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Lessons" />}>
                <AntDTable
                  modelName={modelName}
                  cellRenderers={lessonCellRenderers}
                  rowKey="id"
                  pagination={{
                    currentPage: renderProps.currentPage,
                    pageSize: renderProps.pageSize,
                    showQuickJumper: true,
                    hideOnSinglePage: true,
                    showSizeChanger: !!((user.role === 'admin' || user.role === 'superAdmin')),
                    pageSizeOptions: [10, 20, 25, 50, 100],
                  }}
                  rowSelection={(user.role === 'admin' || user.role === 'superAdmin') ? {
                    selectedRowKeys: selectedLessons,
                    onChange: (keys) => setSelectedLessons(keys),
                    onSelect: (record, selected) => {
                      const allClassIds = get(record, 'classes', []).filter((c) => !c.hasAttendanceConfirmed).map((c) => Number(c.id));
                      const selectedClassIds = selected ? [...selectedClasses, ...allClassIds] : selectedClasses.filter((c) => !allClassIds.includes(c));
                      return setSelectedClasses([...new Set(selectedClassIds)])
                    },
                    onSelectAll: (selected, selectedRows, changeRows) => {
                      const allClassIds = changeRows.reduce((allRows, current) => {
                        allRows.push(...get(current, 'classes', []).filter((c) => !c.hasAttendanceConfirmed).map((c) => Number(c.id)))
                        return allRows;
                      }, []);
                      const selectedClassIds = selected ? [...selectedClasses, ...allClassIds] : selectedClasses.filter((c) => !allClassIds.includes(c));
                      return setSelectedClasses([...new Set(selectedClassIds)])
                    },
                    preserveSelectedRowKeys: true,
                    getCheckboxProps: (record) => ({
                      disabled: get(record, 'classes', []).filter((c) => !c.hasAttendanceConfirmed).length === 0,
                    }),
                  } : false}
                  scroll={{ x: 'max-content', y: '65vh' }}
                  expandable={{
                    expandedRowRender: (lesson) => (
                      <AntDTable
                        modelName={modelName}
                        cellRenderers={user.role === 'instructor' ? classCellRenderers.filter((c) => c.title !== 'Action') : user.role === 'parent' ? classCellRenderers.filter((c) => c.key !== 'note') : classCellRenderers}
                        pagination={false}
                        rowKey="id"
                        data={get(lesson, 'classes', [])}
                        isExpanded
                        rowSelection={(user.role === 'admin' || user.role === 'superAdmin') ? {
                          selectedRowKeys: selectedClasses,
                          onChange: (keys) => setSelectedClasses(keys),
                          onSelect: (record, selected, selectedRows) => {
                            const lessonInfo = lessonsArray.find((l) => Number(l.id) === Number(get(record, 'lesson.id', null)));
                            const lessonClasses = get(lessonInfo, 'classes', []).filter((c) => !c.hasAttendanceConfirmed).map((c) => Number(c.id));
                            const selectedItems = selectedRows.filter((r) => get(r, 'id', null)).map((c) => Number(c.id));
                            if (lessonClasses.every((elem) => selectedItems.includes(elem))) {
                              return setSelectedLessons([...new Set([...selectedLessons, Number(get(record, 'lesson.id', null))])])
                            }
                            return setSelectedLessons(selectedLessons.filter((l) => Number(l) !== Number(get(record, 'lesson.id', null))))
                          },
                          preserveSelectedRowKeys: true,
                          hideSelectAll: true,
                          getCheckboxProps: (record) => ({
                            disabled: record.hasAttendanceConfirmed,
                          }),
                          columnWidth: 98,
                          renderCell: (checked, record, index, originNode) => <div style={{ textAlign: 'right' }}> {originNode} </div>,
                        } : false}
                      />
                    ),
                    defaultExpandAllRows: true,
                  }}
                  {...renderProps}
                  data={sortedLessonsArray}
                />
              </ConfigProvider>
            )
          }}
        />
      </ModelContainer>
    </Wrapper>
  )
};

const mapStateToProps = (state) => ({
  authenticated: state.authentication.authenticated,
  user: selectUser(state.authentication),
});

const mapDispatchToProps = (dispatch) => ({
  onSubmitSuccess: (values) => dispatch(signInRequest(values)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListView);
